//*// BOOTSTRAP GRID //*//

//*// This is exactly BOOTSTRAP GRID, replace it for your system

@media (min-width: @plone-screen-sm-min) {
  // body { background: yellow; }
}
@media (min-width: @plone-screen-md-min) {
  // body { background: orange; }
}
@media (min-width: @plone-screen-lg-min) {
  // body { background: lightblue; }
}
// @media (min-width: @plone-screen-xl-min) {
//   body { background: lightgreen; }
// }

#visual-portal-wrapper {
  @media (min-width: @plone-screen-sm-min) {
    // background: yellow;
  }
  @media (min-width: @plone-screen-md-min) {
    // background: orange;
  }
  @media (min-width: @plone-screen-lg-min) {
    // background: lightblue;
  }
  // @media (min-width: @plone-screen-xl-min) {
  //   background: lightgreen;
  // }
}

// Container widths
.container {
  .container-fixed();

  @media (min-width: @plone-screen-sm-min) {
    width: @plone-container-sm;
  }
  @media (min-width: @plone-screen-md-min) {
    width: @plone-container-md;
  }
  @media (min-width: @plone-screen-lg-min) {
    width: @plone-container-lg;
  }
  // @media (min-width: @plone-screen-xl-min) {
  //   width: @plone-container-xl;
  // }
}




// Fluid container
.container-fluid {
  .container-fixed();
}


// Row
.row {
  .clearfix();
  .make-row();
}


// Columns
.make-grid-columns();


// Extra small grid
.make-grid(xs);


// Small grid
@media (min-width: @plone-screen-sm-min) {
  .make-grid(sm);
}


// Medium grid
@media (min-width: @plone-screen-md-min) {
  .make-grid(md);
}


// Large grid
@media (min-width: @plone-screen-lg-min) {
  .make-grid(lg);
}


// X-Large grid
// @media (min-width: @plone-screen-xl-min) {
//   .make-grid(xl);
// }


// toolbar needs to match grid
#edit-zone.plone-toolbar-top .plone-toolbar-container {
  .container-fixed();
  @media (min-width: @plone-screen-sm-min) {
    width: @plone-container-sm;
  }
  @media (min-width: @plone-screen-md-min) {
    width: @plone-container-md;
  }
  @media (min-width: @plone-screen-lg-min) {
    width: @plone-container-lg;
  }
  // @media (min-width: @plone-screen-xl-min) {
  //   width: @plone-container-xl;
  // }
  margin: 0 auto; height: 50px;
}
#edit-zone.plone-toolbar-top.compressed .plone-toolbar-container {width: auto;}