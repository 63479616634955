//*// HEADER //*//

#portal-header {
    margin: 2em auto 15px;
    padding-top: 0;
    margin-bottom: -1em;
    @media (min-width: @plone-grid-float-breakpoint) {
        margin-bottom: 20px;
        margin-top: 2em;
    }
    @media (min-width: @plone-screen-md-min) {
        margin-top: 2em;
        padding-top:15px;
    }
    #portal-logo {
        float: left;
    }
    #portal-languageselector {
        float: left;
        clear: both;
        display: inline-block;
        margin-right: 29px;
        padding-top: 5px;
        li {
            display: inline-block;
            margin-right: 10px;
        }
    }

	#portal-anontools {
		float: right;
		ul {
			padding-right: 0px;
		}
	}
	//mobile search
	#portal-searchbox {
        // disable autozoom on input
        input[type='text'],
        input[type='number'],
        textarea {
          font-size: 16px;
        }

		padding-top: 20px;
		clear:left;
		// width: 67%;
        // position: absolute;
		position: relative;
		z-index: 10;
		// margin-left:3px;
		// margin-top: 60px;
        margin: -1em auto 0 auto;
		white-space: nowrap;
        height: 4em;

        .pat-livesearch {
            left: 10%;
            right: 10%;
            width: unset;
            position: absolute;
        }
        .searchSection {display: none;} //non on mobile
        [type="text"] {
            width: 100%;
            display: inline-block;}

        [type="submit"] {
            display: none; //submit hidden on mobile
        }
        label {font-size: 86.667%}

        //white space between elements inline-block
        form > div {font-size: 0;
            > * {font-size: @plone-font-size-base;}
        }
    }

	// non mobile search
	@media (min-width: @plone-screen-md-min) {
		#portal-languageselector {
			float: right;
			clear: none;
		}

		#portal-anontools {
			float: right;
		}

        #portal-searchbox {
            // reaply absolute positon
            position: absolute;
            z-index: 1000;
            top: 2em;
            right: 0;
            //
            margin-top: 5px; //visual balance
            margin-bottom: -5px; //visual balance
            margin-left: 1em;
            margin-right: 3em;
            padding-top: 0;
            clear: none;
            float:right;
            max-width: 50%;
            width: 250px;
            white-space: nowrap;
            .searchSection {display: inherit;} //yes for non mobile
            [type="text"] {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                width: calc(250px - 70px); //searchboxwidth - submit
                display: inline-block;}
            [type="submit"] {
                display: none; //submit shown on mobile
                width: 70px;
                display: inline-block;
                margin-left: -1px;
                vertical-align: top;
            }
            label {font-size: 86.667%}

            //white space between elements inline-block
            form > div {font-size: 0;
                > * {font-size: @plone-font-size-base;}
            }
        }
    }

    .clearfix(); //clear floats
}
