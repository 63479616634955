//*// SITE NAV //*//
#mainnavigation-wrapper {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 2000;

  @media (min-width: @plone-grid-float-breakpoint) {
    position: relative;
    z-index: 0;
  }
}

.outer-wrapper {
  margin-top: 4em;

  @media (min-width: @plone-grid-float-breakpoint) {
    margin-top: 0em;
  }
}


.plone-nav {
  font-family: @plone-font-family-condensed;
  margin-bottom: 0;
  padding-left: 0; // Override default ul/ol
  list-style: none;
  &:extend(.clearfix all);

  >li {
    position: relative;
    display: inline-block;

    >a {
      position: relative;
      display: inline-block;
      padding: @plone-sitenav-link-padding;
      color: black;
      background: white;
      font-size: ceil((@plone-font-size-base * 1.25));
      ;

      &:hover,
      &:focus {
        text-decoration: none;
        background-color: @plone-sitenav-link-hover-bg;
      }
    }
  }
}

.plone-navbar {
  .clearfix();
  background-color: @plone-sitenav-bg;
  position: relative;
  text-align: center;
  // min-height: @plone-sitenav-height; // Ensure a navbar always shows (e.g., without a .navbar-brand in collapsed mode)
  margin-bottom: @plone-sitenav-margin-bottom;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .17);

  // Prevent floats from breaking the navbar
  &:extend(.clearfix all);


}

.plone-navbar-header {
  .clearfix(); //without extend

  @media (min-width: @plone-grid-float-breakpoint) {
    float: left;
  }
}

.plone-navbar-collapse {
  overflow-x: visible;
  padding-right: @plone-sitenav-padding-horizontal;
  padding-left: @plone-sitenav-padding-horizontal;
  border-top: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
  &:extend(.clearfix all);
  -webkit-overflow-scrolling: touch;

  display: none;

  &.in {
    overflow-y: auto;
    display: block;
  }

  @media (min-width: @plone-grid-float-breakpoint) {
    width: auto;
    border-top: 0;
    box-shadow: none;

    &.plone-collapse {
      display: block !important;
      height: auto !important;
      padding-bottom: 0; // Override default setting
      overflow: visible !important;
    }

    &.in {
      overflow-y: visible;
    }

    // Undo the collapse side padding for navbars with containers to ensure
    // alignment of right-aligned contents.
    .navbar-fixed-top &,
    .navbar-static-top &,
    .navbar-fixed-bottom & {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.plone-navbar-toggle {
  z-index: 1; //just in case
  position: relative;
  float: right;
  margin-top: 6px;
  margin-bottom: 6px;
  padding: 6px 10px;

  background-color: transparent;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid white;
  border-radius: @plone-border-radius-base;

  // We remove the `outline` here, but later compensate by attaching `:hover`
  // styles to `:focus`.
  &:focus {
    outline: 0;
  }

  &:hover {
    // background-color: darken(@plone-sitenav-link-hover-bg, 15%);
    // border-color: darken(@plone-sitenav-link-hover-bg, 25%);
    background-color: black;
    border-color: white;
  }

  // Burgerbar (uncomment for a ≡ menu and comment menu just below)

  .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background: white;
    margin-top: 2px;
  }

  .icon-bar+.icon-bar {
    margin-top: 5px;
  }

  .icon-bar+.icon-bar+.icon-bar {
    margin-bottom: 3px;
  }

  //end burgerbar icon-bar


  // Menu (That option with a word, witch is language-dependant, is to ensure a higher tap ratio on the menu)
  // .icon-bar:after {
  // 	content:"Menu";
  // 	position: relative;
  // 	color: white;
  // }
  // .icon-bar + .icon-bar:after {content: "";}
  //end menu icon-bar

  @media (min-width: @plone-grid-float-breakpoint) {
    display: none;
  }
}

.plone-navbar-nav {
  // margin: -1px -@plone-sitenav-padding-horizontal -1px;
  margin-bottom: 2em;

  >.selected>a {

    &,
    &:hover,
    &:focus {
      color: black;
      background-color: @plone-sitenav-link-hover-bg;
    }
  }


  // Uncollapse the nav
  @media (min-width: @plone-grid-float-breakpoint) {
    // float: left;
    margin-bottom: 0em;

    &.navbar-right:last-child {
      margin-right: -@plone-sitenav-padding-horizontal;
    }
  }
}

// coloring the sections
each(@sections, {
  #portaltab-@{value} a {
    @color: "color-@{value}";
    @hover: "@{color}-hover";
    background: @@color;
    &:hover,
    &:focus {
      background: @@hover;
    }
  }
});
