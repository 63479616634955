#portal-header {
  text-align: center;
  position: relative;

  #portal-logo {
    display: block;
    float: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
  }

  #portal-logo img {
    height: 5em;
  }

  // #portal-searchbox {
  //   text-align: unset;
  //   position: absolute;
  //   top: 1em;
  //   right: 1em;
  // }
  #portal-anontools {
    text-align: unset;
    position: absolute;
    top: 1em;
    left: 1em;

    ul {
      padding: 0;
    }
  }
}

img {
  max-width: 100%;
  height: auto;
}

p {
  margin: 0 0 @plone-line-height-computed;
}


// Document Header

#content header {
  #plone-document-byline {
    margin-bottom: 1em;
  }
}

#content .headerWrapper {
  position: relative;
  width: 100%;
  margin-bottom: 2em;
  min-height: 4em;
}

#content .descriptionWrapper {
  display: table-row;
}

.portaltype-article,
.portaltype-document,
.portaltype-event {
  #main-container {
    max-width: 970px;
    margin-left: auto;
    margin-right: auto;
  }
}

// .headerLeft {
//     padding-top: 2em;
//     position: absolute;
//     height: 100%;
//     width: 40%;
//     border-top: 2px solid @plone-page-header-border-color;
// }
// .headerLeft.full {
//     width: 100%;
// }

.documentFirstHeading {
  padding: 0;
  margin: 0;
  border-bottom: 0;
}

.documentDescription {
  font-size: @plone-font-size-base;
  font-weight: @plone-font-weight-bold;
  color: @plone-text-color;
  margin: 0 0 @plone-line-height-computed;
}

.relatedItems #relatedItemBox ul {
  padding-left: 0;
  list-style-type: none;
}

#main-container {
  margin-top: 2em;
}

// #content-core {
//   margin-right: 4em;
// }


// Item Labels
.itemLabel {
  padding: 0.25em 0.5em;
  position: absolute;
  top: 0;
  right: 0;
  color: black;
  font-size: ceil((@plone-font-size-base * 0.8));
  font-family: @plone-headings-font-family;
}


.sliderLabel {
  right: 1.25em;
  z-index: 1000;
}

each(@sections, {
  @color: "color-@{value}";
  .label.@{value},
  .itemLabel.@{value} {
    background: @@color;
  }
});

// Item Labels
.tileExtras {
  display: flex;
  margin-bottom: -0.25em;
  padding: .5em 0.6em 0em 0.75em;

  .contest {
    // height: 1em;
    display: flex;
    justify-content: center;
    align-content: center;
    line-height: 1;
    padding: 0.2em 0.25em 0.2em 0.2em;
    margin-right: 0.5em;
    font-size: ceil((@plone-font-size-base * 0.8));
    font-family: @plone-headings-font-family;
    border-radius: 0.25em;
    color: black;
    .highlight {
      display: inline-block;
      // font-size: 1.25em;
      // margin-top: -0.15em;
      margin-right: 0.1em;
    }

  }

  .sponsored {
    padding: 0.2em 0;
    display: inline-block;
    line-height: 1;
    // margin-bottom: -0.25em;
    color: #444;
    font-size: ceil((@plone-font-size-base * 0.8));
    font-family: @plone-headings-font-family;
    background: transparent;
    display: inline-block;

  }
}




.template-agenda_view {
  .tileExtras {
    padding: 0;
    .sponsored {
      margin-bottom: 1em;
      // margin-bottom: 0.25em;
  }
  .contest {
    margin-bottom: 1em;
    //   // height: 1em;
  //   display: flex;
  //   justify-content: center;
  //   align-content: center;
  //   padding: 0.15em 0.5em 0.15em 0.35em;
  //   margin-right: 0.5em;
  //   font-size: ceil((@plone-font-size-base * 0.8));
  //   font-family: @plone-headings-font-family;
  //   border-radius: 0.25em;
  //   color: white;
  //   .highlight {
  //     display: inline-block;
  //     font-size: 1.25em;
  //     margin-top: -0.22em;
  //     margin-right: 0.1em;
  //   }
  }

  }
}

.template-layout {
  .sponsored {
    margin-bottom: 1em;
  }
}

// Mixed Listing
.tileItem {
  position: relative;
  margin-bottom: 2em;
}

each(@sections, {
  .tileItem.section-@{value} {
    @color: "color-@{value}";
    border-bottom: 2px solid @@color;
  }
});

.template-mixed_view,
.template-prioritized_view {
  .documentFirstHeading {
    display: none;
  }

  .documentByLine {
    display: none;
  }

  #content {
    .entries {
      position: relative;
    }

    .tileItem {
      // float: left;
      color: black !important;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);

      a {
        color: black;

        &:hover {
          text-decoration: none;
          border-bottom: 0;
        }
      }

      .tileHeadline {
        margin: 0.5em 0.6em 0.4em 0.6em;
        padding: 0;
        font-size: ceil((@plone-font-size-base * 1.25));
      }

      .description {
        margin: 0 1em 0.5em 1em;
      }

    }
  }
}

// div {outline: dotted 1px yellowgreen;}
.template-prioritized_view {
  .documentFirstHeading {
    display: none;
  }

  .documentByLine {
    display: none;
  }

  @media (min-width: @plone-screen-lg-min) {

    .entries.normal .itemWrapper:last-of-type {
      display: none;
    }

  }

}

.template-prioritized_view {
  .sliderWrapper {

    button:active,
    button:focus,
    button:hover {
      box-shadow: none;
    }
  }



  #top-slider {
    .sliderItem {
      margin-bottom: 1em;

      .tileItem {
        box-shadow: none !important;
        margin-bottom: 0;
      }

      .tileItem .tileContent {
        min-height: 7em;
      }
    }

    .slick-list {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    }

    .slick-dots {
      position: absolute;
      top: 8px;
      left: 3px;
      width: 200px;
      height: 20px;
      text-align: left;
    }

    .slick-dots li button::before {
      font-family: "slick";
      font-size: 10px;
      line-height: 20px;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 20px;
      height: 20px;
      content: "•";
      text-align: center;
      opacity: 0.25;
      color: #fff;
      -moz-osx-font-smoothing: grayscale;
      text-shadow: 0px 0px 4px black, 0px 0px 10px white;
    }

    .slick-dots li.slick-active button::before {
      opacity: 0.75;
      color: #fff;
    }

    .slick-prev,
    .slick-next {
      font-size: 0px;
      line-height: 0;
      position: absolute;
      z-index: 1000;
      top: 45%;
      display: block;
      width: 40px;
      height: 40px;
      margin-top: -20px;
      padding: 0px;
      cursor: pointer;
      color: transparent;
      border: medium none;
      outline: medium none;
      background: transparent none repeat scroll 0% 0%;
    }

    .slick-prev::before,
    .slick-next::before {
      font-family: "slick";
      font-size: 40px;
      line-height: 1;
      opacity: 0.75;
      color: #FFF;
      -moz-osx-font-smoothing: grayscale;
    }

    .slick-prev {
      left: 10px;
    }

    .slick-next {
      right: 10px;
    }

  }

  #top-slider {

    .sliderItem {
      margin-bottom: 2em;
    }

    @media (min-width: @plone-screen-xs-min) {
      .sliderItem {
        margin-bottom: 0em;
      }
    }

  }

  // #top-slider {
  //   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  //   .sliderItem {
  //     .tileItem {
  //       box-shadow: none!important;
  //       margin-bottom: 0;
  //     }
  //     .tileItem .tileImage {
  //     }
  //     .tileItem .tileContent {
  //       min-height: 7em;
  //     }
  //   }

  //   .slick-dots {
  //       position: absolute;
  //       bottom: 110px;
  //       display: block;
  //       width: 100%;
  //       padding: 0px;
  //       list-style: outside none none;
  //       text-align: center;
  //   }
  //   .slick-dots li button::before {
  //       font-family: "slick";
  //       font-size: 10px;
  //       line-height: 20px;
  //       position: absolute;
  //       top: 0px;
  //       left: 0px;
  //       width: 20px;
  //       height: 20px;
  //       content: "•";
  //       text-align: center;
  //       opacity: 0.25;
  //       color: #fff;
  //       -moz-osx-font-smoothing: grayscale;
  //   }
  //   .slick-dots li.slick-active button::before {
  //       opacity: 0.75;
  //       color: #fff;
  //   }
  // }

  #party-slider {
    border: 2px solid @color-parties;
    padding: 1em;
    margin-top: 1.5em;
    margin-bottom: 2em;
    z-index: 100;

    .itemLabel {
      padding: 0.25em 0.5em;
      position: absolute;
      top: -8px;
      left: 15px;
      right: auto;
      color: #000;
      font-size: 16px;
      font-family: "Roboto Condensed", "Arial Narrow", sans-serif;
    }

    .slick-prev,
    .slick-next {
      font-size: 0px;
      line-height: 0;
      position: absolute;
      z-index: 1000;
      top: 50%;
      display: block;
      width: 40px;
      height: 40px;
      margin-top: -20px;
      padding: 0px;
      cursor: pointer;
      color: transparent;
      border: medium none;
      outline: medium none;
      background: transparent none repeat scroll 0% 0%;
    }

    .slick-prev::before,
    .slick-next::before {
      font-family: "slick";
      font-size: 40px;
      line-height: 1;
      opacity: 0.75;
      color: #FFF;
      -moz-osx-font-smoothing: grayscale;
    }

    .slick-prev {
      left: 10px;
    }

    .slick-next {
      right: 10px;
    }

    .slick-slider {
      margin-bottom: 0;

      img {
        max-width: unset;
        height: unset;
      }

      .slick-slide {
        margin-left: 0.5em;
        margin-right: 0.5em;
      }
    }

  }

}

.colSizer {
  display: none;
}

.template-agenda_view {
  .divider {
    display: block;
    clear: both;
    border-bottom: 1px solid @plone-gray-lighter;
    margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 1em;
  }

  .documentFirstHeading {
    display: none;
  }

  article.newdate {
    clear: both;
    margin-top: 1em;
  }

  .vevent div.cal_date {
    float: none;
    vertical-align: top;
    text-align: center;
    margin: 0 1.5em 0.5em 0em;
    background-color: #FFF;
    border-radius: 0.5em;
    min-width: 100%;
  }

  .vevent span.cal_wkday {
    font-size: 1.2em;
    line-height: 1.25em;
    padding: 0.3em 0.5em;
    color: #fff;
    background: #007BB3 none repeat scroll 0% 0%;
    display: block;
    float: left;
  }

  .vevent span.cal_day {
    font-size: 1.2em;
    font-weight: normal;
    line-height: 1.25em;
    padding: 0.3em 0.15em 0.3em 0.5em;
    display: block;
    float: left;

    &:after {
      content: ".",
    }
  }

  .vevent span.cal_month {
    display: block;
    float: left;
    font-size: 1.2em;
    line-height: 1.25em;
    padding: 0.3em 0.5em 0.3em 0em;
    font-weight: normal;
    background: #fff;
    color: @plone-text-color;

  }

  .vevent div.cal_info {
    margin-left: 0em;
  }

  .tileItem {
    margin-bottom: 0;
  }

  .tileHeadline {
    padding-top: 0;
  }

  @media (min-width: @plone-grid-float-breakpoint) {
    .divider {
      display: block;
      clear: both;
      border-bottom: 0;
      border-top: 1px solid @plone-gray-lighter;
      margin-left: 1em;
      margin-bottom: 0em;
    }

    #content-core {
      margin-right: 0;
      margin-left: 8em;
    }

    .vevent div.cal_info {
      margin-left: 0em;
    }

    article.newdate {
      clear: both;
      margin-top: 0em;
    }

    article {
      min-height: 8em;

      .tileItem {
        margin-bottom: 2em;
      }

      .tileHeadline {
        font-size: @plone-font-size-h4;
        margin-top: 0;
        padding-top: 0;
      }

      p.description {
        margin: 0.5em 0 1em 0;
      }

      .vevent div.cal_date {
        float: left;
        vertical-align: top;
        text-align: center;
        margin: 0 1.5em 0.5em -9em;
        background-color: #FFF;
        border-radius: 0.5em;
        min-width: 4.4em;
      }

      .vevent span.cal_wkday {
        font-size: 1.1em;
        line-height: 1.3em;
        padding: 0.25em 0;
        color: #fff;
        background: #007BB3 none repeat scroll 0% 0%;
        display: block;
        float: none;
      }

      .vevent span.cal_day {
        font-size: 1.7em;
        font-weight: 700;
        display: block;
        float: none;
        padding: 0.3em 0 0 0;
        line-height: 1.3em;
      }

      .vevent span.cal_month {
        display: block;
        float: none;
        font-size: 1em;
        line-height: 1.3em;
        font-weight: normal;
        padding: 0em 0.3em 0.5em 0.3em;
        background: #fff;
        color: @plone-text-color;

      }
    }
  }


  .event_date,
  .event_location,
  .vevent .horizontal.metadata {
    margin: 0 0 0.25em 0 !important;
  }

  article.vevent a.event_ical img {
    vertical-align: baseline;
    height: 0.9em;
    width: auto;
  }

  article.vevent a.google_maps_link img {
    vertical-align: baseline;
    height: 1em;
    width: auto;
  }

}

.template-agenda_view,
.eventSummaryTile {
  abbr.dtstart {
    margin-left: 0.25em;
  }
}

.template-album_view {
  #content-core {
    margin-right: -30px;
  }

  .photoAlbumEntry {
    margin: 0px 13px 13px 0px;
    vertical-align: middle;

    a {
      width: 230px;
      height: 230px;
    }

    img {
      max-width: 215px;
      max-height: 215px;
    }

  }
}

.template-image_next_view {
  #content {
    margin-bottom: 0;
  }

  #content-core {
    margin-top: 3em;
    text-align: center;
  }

  #viewlet-below-content .listingBar {
    margin-bottom: 4em;

    .previous,
    .next {
      float: none;
      margin: 0 1em;
    }
  }
}



#content-header {

  .portlet,
  .portlet.collectionFilter {
    border: 0;
    margin-bottom: 0;
    border-radius: 0;
    box-shadow: none;
    background: transparent;
    border: 0;

    .portletHeader {
      display: none;
    }
  }

  .portlet-collectionfilter,
  .filterContent {
    text-align: center;
    // margin-bottom: 2em;
    margin-top: 2em;
    padding: 0;
    background: transparent;
    border: 0;

    ul {
      margin: 0;
      padding: 0;
    }

    li,
    li.filterItem {
      list-style-type: none;
      display: inline-block;
      margin-bottom: 0.5em;

      a {
        display: inline;
        padding: 0.2em 0.6em 0.3em;
        font-size: 12px;
        font-weight: 400;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 4px;
        background-color: #E5E5E5;

        &::before {
          content: "";
        }

        span {
          padding: 0;
        }
      }
    }
  }
}

// Button Style
.button {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-family: @plone-font-family-condensed;
  font-weight: @plone-font-weight-bold;
  text-align: center;
  text-shadow: 0 1px rgba(0, 0, 0, .25);
  vertical-align: middle;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  .button-size(@plone-padding-base-vertical; @plone-padding-base-horizontal; @plone-font-size-base; @plone-line-height-base; @plone-border-radius-large);
  .user-select(none);

  &,
  &:active,
  &.active {
    &:focus {
      .tab-focus();
    }
  }

  &:hover,
  &:focus {
    color: @plone-btn-standalone-color;
    text-decoration: none;
    .box-shadow(0 1px 2px rgba(0, 0, 0, .25));
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    .box-shadow(inset 0 3px 5px rgba(0, 0, 0, .125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
    pointer-events: none; // Future-proof disabling of clicks
    .opacity(.5);
    .box-shadow(none);
  }
}

.btnNext {
  //gray
  .button-variant(@plone-btn-context-color; @plone-btn-context-bg; @plone-btn-context-border);
}

.pagination .next {
  text-align: center;
}

// Section specific colors
each(@sections, {
  .section-@{value} {
    @color: "color-@{value}";
    @hover: "@{color}-hover";

    article .vevent span.cal_wkday,
    #content-header .portlet-collectionfilter li a,
    #content-header .filterContent li a {
      background: @@color;
    }
  
    article.topentry h2 a,
    article.topentry h3 a {
      color: @@color;
  
      & :hover {
        border-bottom-color: @@color;
      }
    }
  
  }
});

.listingBar {
  width: 100%;
  text-align: center;

  .previous {
    float: left;
  }

  .next {
    float: right;
  }
}

#searchform h1 {
  margin-top: 1em;
  margin-bottom: 1em;
}

.searchResults {
  list-style: none;
  padding: 1.5em;

  .result-title {
    img.image-icon {
      margin-right: 0.25em;
      vertical-align: bottom;
      float: none;
    }

    a {
      font-size: 1.25em;
    }
  }

  >li {
    margin: 0 0 2.5rem 0 !important;
  }

  .resultItem {
    display: flex;
  }

  .resultImage {
    img {
      margin-right: 1em;
      max-width: 200px;

    }
  }

  .croppedDescription {
    margin-bottom: 0.5em !important;
  }
}

// adspace styles
.adspace {
  margin: 0 auto;
  text-align: center;
}

.aditem {
  margin-left: auto;
  margin-right: auto;
}

.header_adspace .aditem {
  border: 1px solid #eee;
  background: #eee;
  margin: 1em auto;
}

.header_adspace {
  margin: 2em auto 1em auto;
}

.high_adspace>div {
  margin: 0em auto 1em auto;
}

.high_adspace:first-of-type {
  margin: 0em auto 1.5em auto;
}

.medium_adspace>div {
  margin: 0em auto 2.5em auto;
}

.middle_adspace>div {
  margin: 0em auto 2.5em auto;
}

.remains_adspace {
  max-width: 320px;
  max-height: 250px;
  margin-bottom: 2em;
  // background: lightcoral;
  // div {
  //   margin: 0em auto 2.5em auto;
  // }
}

.detail_bottom_adspace>div {
  margin: 0em auto 6em auto;
}

.detail_remains_adspace {
  display: flex;
  flex-direction: column;
  align-items: center;

  .admarker {
    margin-bottom: 1.5em;
  }
}

// adspace styles
.tileItem.medium_rectangle {
  width: 300px !important;
  height: 250px !important;
}

.tileItem.rectangle {
  width: 300px !important;
  height: 100px !important;
}

.tileItem.full_banner {
  width: 468px !important;
  height: 60px !important;
}

.tileItem.billboard {
  max-width: 970px !important;
  height: 250px !important;
  background: #eee;
  margin-left: auto;
  margin-right: auto;
  float: none !important;
}

.tileItem.large_leaderboard {
  max-width: 970px !important;
  height: 90px !important;
  background: #eee;
  margin-left: auto;
  margin-right: auto;
  float: none !important;
}

.tileItem.leaderboard {
  max-width: 728px !important;
  height: 90px !important;
}


.mosaic-ISummaryText-summary-tile .mosaic-rich-text-toolbar {
  right: 0;
}

// types
body#visual-portal-wrapper.pat-plone .outer-wrapper {
  .contenttype-article:before {
    content: '\e80e';
  }

  .contenttype-gallery:before {
    content: '\e810';
  }

  .contenttype-prioritizedpage:before {
    content: '\e80e';
  }
}

// on right on toolbar
body#visual-portal-wrapper.pat-plone #edit-zone {
  .contenttype-article:after {
    content: '\e80e';
  }

  .contenttype-gallery:after {
    content: '\e810';
  }

  .contenttype-prioritizedpage:after {
    content: '\e80e';
  }
}

// actions
.icon-cropping::before {
  content: "\e832";
}

.noembed-meta-info {
  display: none;
}

.documentByLine {
  font-size: 90%;
}

#portal-footer-wrapper {
  padding: 30px 0;
  text-align: left;
  font-family: @plone-font-family-condensed;

  .portletWrapper:first-child {
    // display: unset;
    border-bottom: none;
    // padding-bottom: unset;
    // margin-bottom: unset;
  }

  .portletWrapper {
    display: unset;
    border-bottom: none;
    padding-bottom: unset;
    margin-bottom: 2em;
  }

  .portlet .portletHeader {
    background: transparent;
    font-weight: @plone-font-weight-bold;
    font-size: @plone-font-size-base;
    padding: 0 0;
    color: white;
  }

  .portlet .portletContent>* {
    color: @plone-gray-lighter;
  }

  p {
    border-bottom: 0;
  }

  a {
    color: @plone-gray-lighter;

    &:hover {
      color: @plone-gray-lighter;
    }
  }
}

// .container { background: pink!important; }

#formfield-form-widgets-IRelatedItems-relatedItems .pattern-relateditems-container .recentlyUsed {
  display: none;
}

#cookieconsent\:desc {
  line-height: 1;
  font-size: 90%;
}

.pat-livesearch .livesearch-results {
  @media (min-width: @plone-screen-md-min) {

    width: 400px !important;
    left: unset !important;
    right: -20px !important;
  }

  li {
    white-space: normal;
    margin: 0;
    padding: 5px;
    cursor: pointer;
    display: flex;
    text-align: left;

    .title {
      padding-left: 0.5em;
      margin-top: 0.25em;
    }

    img {
      max-width: 150px;
    }
  }

  .load-more {
    .page {
      display: none;
    }

    a {
      margin-right: 1em;
      display: inline-block;
    }
  }
}

// customizations over time moved from custom styles controlpanel into here
#portaltab-facebook > a,
#portaltab-instagram > a,
#portaltab-newsletter-abo > a {
  padding: 0.2em 0;
  width: 2.5em;
}

#portaltab-facebook > a::before,
#portaltab-instagram > a::before,
#portaltab-newsletter-abo > a::before {
  content: '';
  background-color: black;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  width: 100%;
  height: 100%;
  
  position: absolute;
  bottom: 0;
} 

#portaltab-facebook > a::before {
  background-image: url('++resource++gaych/facebook-square.svg');
}

#portaltab-instagram > a::before {
  background-image: url('++resource++gaych/instagram.svg');
}
#portaltab-newsletter-abo > a::before {
  background-image: url('++resource++gaych/newsletter.svg');
}

#content-header .portlet-collectionfilter li,
#content-header .filterContent li,
#content-header .portlet-collectionfilter li.filterItem,
#content-header .filterContent li.filterItem {
  display: inline-flex;
  padding: 0 1em;
}

.template-embed_agenda_view #portal-footer-wrapper {
  display: none;
}

.sharethis-inline-share-buttons {
  padding-top: 1rem;
}