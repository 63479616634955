// page header overwrite and styles
.template-layout {
  .headerLeft {
    margin-bottom: 1em;
  }

  .headerRight {
    margin-bottom: 1em;
  }

  .documentFirstHeading {
    position: relative;
  }

  .addthis_sharing_toolbox {
    margin-top: 0.25em;
    margin-left: -2px;
  }

  @media (min-width: @plone-grid-float-breakpoint) {
    .headerLeft {
      margin-bottom: 0em;
    }

    .headerRight {
      margin-bottom: 0em;
    }

    .mosaic-grid-row.headerRow {
      position: relative;
      margin-bottom: 2em;

      //workaround for missing row class
      margin-left: -15px;
      margin-right: -15px;

      .headerLeft {
        width: 40%;
        height: 100%;
        min-height: 8em;
        position: absolute;

        // .mosaic-tile:first-child {
        //   border-top: 2px solid @plone-text-color;
        // }
        // special h1 style for view
        .documentFirstHeading {
          position: absolute;
          bottom: 0;
          margin: 0;
        }
      }

      .headerRight {
        width: 60%;
        float: right;
      }
    }
  }
}

//workaround for missing row class
.mosaic-grid-row.footerRow {
  margin-left: -15px;
  margin-right: -15px;
}

.mosaic-grid-row.adSpaceBelowRow {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 3em;
}

// absolute positioning for view mode
.layout-gaych-article,
.layout-custom.portaltype-article .headerRow,
.layout-custom.portaltype-document .headerRow,
.layout-custom.portaltype-event .headerRow {
  .mosaic-ILeadImageBehavior-image-tile img {
    width: 100%;
    margin-bottom: 0.3em;
  }
}

.layout-gaych-article,
.layout-custom.template-main_template.portaltype-article,
.layout-custom.template-main_template.portaltype-document,
.layout-custom.template-main_template.portaltype-event {

  .namedblobimage-field img,
  .mosaic-ILeadImageBehavior-image-tile img {
    width: 100%;
  }
}

.template-event.mosaic-enabled,
.layout-gaych-event,
.portaltype-event.mosaic-enabled {

  // .headerRow .headerRight,
  // .headerRow .headerLeft {
  //   width: unset;
  //   float: unset;
  // }
  .columnLeft {
    @media (min-width: @plone-grid-float-breakpoint) {
      padding-right: 3em;
      // width: auto;
      // height: auto;
      // position: relative;
    }

    .mosaic-IDublinCore-title-tile {
      margin-top: 4em;
    }

    .eventSummaryTile {
      font-size: 110%;
      margin: 0.5em 0 1em 0;

      p {
        margin: 0;
      }
    }
  }

  .sideColumn {
    border-top: 0 !important;
  }
}

@media (max-width: @plone-screen-sm-min) {
  #content {
    >.mosaic-grid-row {
      >.mosaic-grid-cell {

        // background: pink;
        padding-left: 0;
        padding-right: 0;
      }

    }

    // margin-bottom: 4em;
  }
}

.contentColumn {
  @media (min-width: @plone-screen-md-min) {
    padding-right: 4em;
    // margin-bottom: 4em;
  }
}

.sideColumn {
  font-family: @plone-font-family-condensed;
  background: @light-grey;
  width: 90%;
  margin-left: 15px;
  margin-right: 15px;

  @media (min-width: @plone-screen-md-min) {
    padding: 0;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 4em;
    width: 32%;

    p {
      margin-bottom: 0.5em;
    }

    .mosaic-text-tile {
      margin: 1em;
    }

    .mosaic-tile {
      margin: 1em 1em 0em 1em;
    }

    .mosaic-plone\.app\.standardtiles\.image-tile,
    .mosaic-ILeadImageBehavior-image-tile {
      margin: 0em 0em 1em 0em;
    }
  }
}

#categories-filed-under {
  display: none;
}

.mosaic-plone\.app\.standardtiles\.embed-tile {
  margin-bottom: 2em;
}

.mosaic-plone\.app\.standardtiles\.rawembed-tile {
  .mosaic-tile-content {
    .tileItem {
      margin-bottom: 2em;
    }

  }
}

.mosaic-plone\.app\.standardtiles\.rawembed-tile,
.mosaic-IRichTextBehavior-text-tile,
.mosaic-ISummaryText-summary-tile {
  iframe {
    min-width: auto !important;
    max-width: 100% !important;
  }
}



// .mosaic-plone\.app\.standardtiles\.keywords-tile {
//   padding-top: 1em;
//   margin-bottom: 2em;
// }

.mosaic-plone\.app\.standardtiles\.related_items-tile {
  border-bottom: 2px #ddd solid;
}

#relatedItemBox {
  margin-top: 1em;

  header {
    margin-bottom: 1em;
    padding-bottom: 0.5em;
    border-bottom: 2px #ddd solid;
    font-weight: bold;
  }

  ul.row {
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
  }

  .rel-item-link {
    display: flex;
    margin-bottom: 1em;

    &:hover {
      text-decoration: none;
    }

    .rel-image {
      width: 45%;
      padding-right: 1em;
    }

    .rel-headline {
      width: 55%;
      font-size: 16px;
      margin: 0;
    }
  }
}

.mosaic-gaych\.help_document-tile {
  .help-document-wrapper {
    background: #c9eefb;
    padding: 1em 1em 0em 1em;
    margin-bottom: 2em;
  }
}

.mosaic-gaych\.contest-tile {
  .mosaic-tile-content {
    .contest-info {
      background: #eee;
      padding: 1em 1em 0em 1em;
      margin-bottom: 1.5em;
    }
  }
}


.mosaic-plone\.app\.standardtiles\.document_byline-tile {
  .documentByLine {
    margin-top: 0.5em !important;
  }
}

.portaltype-collection .documentByLine {
  display: none;
}

.mosaic-IDublinCore-title-tile {
  margin-top: 4em;
}

.mosaic-tile-content #plone-document-byline {
  margin-top: 1em;
}

.columnRight .mosaic-ILeadImageBehavior-image-tile {
  margin-bottom: 1em;
}

.section-blog {
  .columnLeft .mosaic-IDublinCore-description-tile .documentDescription {
    font-weight: normal;
  }
}

// base highlights
.columnLeft.mosaic-grid-cell .mosaic-tile:first-child,
.headerLeft.mosaic-grid-cell .mosaic-tile:first-child {
  border-top: 2px solid @plone-link-color;
}

.section-border {
  border: 2px solid @plone-link-color;
}

.section-background {
  background: 2px solid @plone-link-color;
}

.sideColumn {
  border-top: 2px solid @plone-link-color;
  border-bottom: 2px solid @plone-link-color;
}

// section highlights
each(@sections, {
  .section-@{value} {
    @color: "color-@{value}";
    .columnLeft.mosaic-grid-cell .mosaic-tile:first-child,
    .headerLeft.mosaic-grid-cell .mosaic-tile:first-child {
      border-top: 2px solid @@color;
    }
  
    .section-border {
      border: 2px solid @@color;
    }
  
    .section-background {
      background: 2px solid @@color;
    }
  
    .sideColumn {
      border-top: 2px solid @@color;
      border-bottom: 2px solid @@color;
    }
  
  }
});

// grid override
body.template-layout,
body.mosaic-enabled {
  &.no-portlet-columns {
    .outer-wrapper>.container {
      // remove fixed width of wrapping container
      width: auto;

      .mosaic-grid-row {
        margin-left: auto;
        margin-right: auto;

        // add width to row instead
        @media (min-width: @plone-screen-sm-min) {
          max-width: @plone-container-sm;
          width: 100%;
        }

        @media (min-width: @plone-screen-md-min) {
          max-width: @plone-container-md;
          width: 100%;
        }

        @media (min-width: @plone-screen-lg-min) {
          max-width: @plone-container-lg;
          width: 100%;
        }

        @media (min-width: @plone-screen-xl-min) {
          max-width: @plone-container-xl;
          width: 100%;
        }

        .mosaic-innergrid-row {
          max-width: auto;
          width: 100%;
          .make-row(@gutter: @plone-grid-gutter-width);
        }

        &.mosaic-grid-row-fluid {
          margin-left: (@plone-grid-gutter-width / -2);
          margin-right: (@plone-grid-gutter-width / -2);
          padding-left: (@plone-grid-gutter-width / 2);
          padding-right: (@plone-grid-gutter-width / 2);
          width: auto;
          max-width: none;
        }
      }
    }
  }
}

// row widths for logged-in users

body.plone-toolbar-left-default {
  &.no-portlet-columns {
    .outer-wrapper>.container {
      .mosaic-grid-row {
        @media (min-width: (@plone-screen-sm-min + @plone-left-toolbar)) {
          max-width: (@plone-container-sm - @plone-left-toolbar);
          width: 100%;
        }

        @media (min-width: (@plone-screen-md-min + @plone-left-toolbar)) {
          max-width: (@plone-container-md - @plone-left-toolbar);
          width: 100%;
        }

        @media (min-width: (@plone-screen-lg-min + @plone-left-toolbar)) {
          max-width: (@plone-container-lg - @plone-left-toolbar);
          width: 100%;
        }

        @media (min-width: (@plone-screen-xl-min + @plone-left-toolbar)) {
          max-width: (@plone-container-xl - @plone-left-toolbar);
          width: 100%;
        }
      }
    }
  }
}

body.plone-toolbar-left-expanded {
  &.no-portlet-columns {
    .outer-wrapper>.container {
      .mosaic-grid-row {
        @media (min-width: (@plone-screen-sm-min + @plone-left-toolbar-expanded)) {
          max-width: (@plone-container-sm - @plone-left-toolbar-expanded);
          width: 100%;
        }

        @media (min-width: (@plone-screen-md-min + @plone-left-toolbar-expanded)) {
          max-width: (@plone-container-md - @plone-left-toolbar-expanded);
          width: 100%;
        }

        @media (min-width: (@plone-screen-lg-min + @plone-left-toolbar-expanded)) {
          max-width: (@plone-container-lg - @plone-left-toolbar-expanded);
          width: 100%;
        }

        @media (min-width: (@plone-screen-xl-min + @plone-left-toolbar-expanded)) {
          max-width: (@plone-container-xl - @plone-left-toolbar-expanded);
          width: 100%;
        }
      }
    }
  }
}